<script setup lang="ts"></script>

<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 10.3889C1 6.38803 1 4.3876 1.86682 2.91806C2.35186 2.09578 3.01733 1.40443 3.80883 0.900532C5.22336 0 7.14891 0 11 0C14.8511 0 16.7766 0 18.1912 0.900532C18.9827 1.40443 19.6481 2.09578 20.1332 2.91806C21 4.3876 21 6.38803 21 10.3889V11.6111C21 15.612 21 17.6124 20.1332 19.0819C19.6481 19.9042 18.9827 20.5956 18.1912 21.0995C16.7766 22 14.8511 22 11 22C7.14891 22 5.22336 22 3.80883 21.0995C3.01733 20.5956 2.35186 19.9042 1.86682 19.0819C1 17.6124 1 15.612 1 11.6111V10.3889ZM5.70588 7.33333C5.70588 6.65832 6.23261 6.11111 6.88235 6.11111H15.1176C15.7674 6.11111 16.2941 6.65832 16.2941 7.33333C16.2941 8.00835 15.7674 8.55556 15.1176 8.55556H6.88235C6.23261 8.55556 5.70588 8.00835 5.70588 7.33333ZM6.88235 11C6.23261 11 5.70588 11.5472 5.70588 12.2222C5.70588 12.8972 6.23261 13.4444 6.88235 13.4444H10.4118C11.0615 13.4444 11.5882 12.8972 11.5882 12.2222C11.5882 11.5472 11.0615 11 10.4118 11H6.88235Z" fill="url(#paint0_linear_549_7879)" />
        <defs>
            <linearGradient
                id="paint0_linear_549_7879"
                x1="11"
                y1="0"
                x2="11"
                y2="22"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
        </defs>
    </svg>
</template>
